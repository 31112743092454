<template>
  <div>
    <CRow class="justify-content-center">
      <CCol sm="10" lg="12">
        <CRow class="px-3 align-items-center">
          <CCol sm="3" class="py-3">
            <CInput
              label="Start Date"
              placeholder="Enter your name"
              type="date"
              v-model="start_date"
            />
          </CCol>
          <CCol sm="3" class="py-3">
            <CInput
              label="Stop Date"
              placeholder="Enter your name"
              type="date"
              v-model="stop_date"
            />
          </CCol>
          <CCol sm="3" class="py-3">
            <CInput
              label="Search"
              placeholder="Enter your name"
              v-model.trim="search"
            />
          </CCol>
          <CCol sm="3" class="py-3">
            <CButton
              color="primary"
              class="mt-2"
              @click.prevent="transactionHistory(1)"
            >
              Submit
            </CButton>
          </CCol>
        </CRow>
        <div v-if="transactions.length > 0">
          <div class=" table-responsive">
            <v-table
              :data="transactions"
              :currentPage.sync="current"
              :pageSize="20"
              @totalPagesChanged="total = $event"
              class="table col-12 center"
            >
              <thead slot="head">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Title</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Reference</th>
                  <th scope="col">Date</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody slot="body" slot-scope="{ displayData }">
                <tr
                  v-for="item in displayData"
                  :key="item.transaction.id"
                  class="pointer"
                  @click="showMore(item)"
                >
                  <td>
                    <CImg
                      :src="item.image_url"
                      width="50px"
                      height="50px"
                      shape="rounded"
                    />
                  </td>
                  <td scope="row">{{ item.title }}</td>
                  <td>{{ item.transaction.payable_amount }}</td>
                  <td>{{ item.transaction.reference }}</td>
                  <td>{{ item.transaction.readable_date }}</td>
                  <td>
                    <span id="dot" :class="getBadge(item.status)"></span>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </div>
          <Pagination
            :activePage.sync="next_page"
            :pages="total"
            :onActivePageChange="onPageChange()"
          />
        </div>
      </CCol>
    </CRow>

    <CModal
      title="Transaction Details"
      :show.sync="myModal"
      size="lg"
      :no-close-on-backdrop="true"
      :centered="true"
    >
      <CRow>
        <!-- <p ref="clay" class="display">Hlloe</p> -->
        <CCol sm="12" class="">
          <CRow>
            <CCol
              v-for="(item, index) in readables"
              :key="index"
              class="pointer col-12"
              md="6"
            >
              <P class="text font-weight-bolder">{{ item.label }}</P>
              <p
                v-html="item.value"
                class="text font-weight-normal font-italic"
              ></p>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <template #footer>
        <a href="#" class="text-center">
          Download PDF
        </a>
      </template>
    </CModal>
  </div>
</template>

<script>
import ApiService from "../services/api.service";
import Pagination from "@/components/CPagination.vue";

export default {
  name: "Transactions",
  components: {
    Pagination,
  },
  data() {
    return {
      myModal: false,
      darkModal: false,
      // file: null,
      transactions: [],
      readables: [],
      start_date: "",
      stop_date: "",
      search: "",
      next_page: 2,
      current: 1,
      total: 1,
    };
  },
  methods: {
    async transactionHistory(page) {
      try {
        const response = await ApiService.get(
          `/partners/transactions?page=${page}&start_date=${this.start_date}&stop_date=${this.stop_date}&type=transaction&search=${this.search}`
        );
        console.log(response);
        console.log(response.data.data);
        // this.file = response.data.data;
        this.current = response.data.data.current_page;
        this.total = response.data.data.last_page;
        this.transactions = response.data.data.data;
      } catch (error) {
        console.log(error.response);
      }
    },
    getBadge(status) {
      return status === "success" ? "text-success" : "text-warning";
    },

    showMore(item) {
      this.transactions.forEach((transaction) => {
        if (transaction.transaction.id === item.transaction.id) {
          console.log(item.transaction.id);
          console.log(item.readable);
          this.readables = item.readable;
          this.myModal = true;
        }
      });
    },
    closeModal() {
      // this.$refs.clay.click()
      this.myModal = false;
    },
    // save() {
    //   let onActivePageChange = "onPageChange()";
    // },
    onPageChange() {
      if (this.current != this.next_page) {
        this.transactionHistory(this.next_page);
      }
    },
  },
  mounted() {
    this.transactionHistory(1);
  },
};
</script>

<style scoped>
#dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-style: solid;
  border-width: 4px;
  border-radius: 100%;
  /* color: red; */
}
.pointer {
  cursor: pointer;
}
.display {
  display: none;
}
</style>
